import { RichText } from 'prismic-reactjs';
import {
  Content,
  Section,
  StyledContainer,
  StyledImage,
  StyledLinkButtonWithGradientHover,
  Title,
} from './coverWithInfo.styles';
import { ICoverWithInfo } from './interfaces';
import { imageSerializer } from 'utils/imageSerializer';
import { StyledContentWrapper } from './coverWithInfo.styles';

export const CoverWithInfo = ({
  contactButton,
  title,
  backgroundImage,
}: ICoverWithInfo) => {
  return (
    <Section>
      <StyledImage {...imageSerializer(backgroundImage)} />
      <StyledContainer>
        <StyledContentWrapper>
          <Content>
            <Title>{RichText.asText(title)}</Title>
            {contactButton?.action_link?.url && (
              <StyledLinkButtonWithGradientHover
                href={contactButton.action_link.url}
              >
                {RichText.asText(contactButton?.action_label)}
              </StyledLinkButtonWithGradientHover>
            )}
          </Content>
        </StyledContentWrapper>
      </StyledContainer>
    </Section>
  );
};
