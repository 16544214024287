import { RichText } from 'prismic-reactjs';

import { IShowreelFeature, IShowreelProps } from './interfaces';
import {
  Caption,
  CloseButton,
  Cursor,
  Feature,
  Features,
  FeaturesContainer,
  Gradient,
  Play,
  Section,
  Span,
  StyledLinkButtonWithGradientHover,
  Title,
  Video,
  VideoContainer,
} from './showreel.styles';
import { useShowreel } from './showreel.hooks';

export const Showreel = ({
  title,
  subtitle,
  items,
  cursorCaption,
  contact_us,
}: IShowreelProps) => {
  const {
    isPreview,
    setPreview,
    previewVideoRef,
    cursorRef,
    isSafari,
    handleShowreelClick,
  } = useShowreel();

  return (
    <Section>
      <Title>
        {title && RichText.asText(title)}
        {subtitle && <Span>{RichText.asText(subtitle)}</Span>}
      </Title>

      <FeaturesContainer>
        <Features>
          {items.map((item: IShowreelFeature) => {
            const gradientText: string = RichText.asText(item.gradient_text);

            return (
              <Feature key={gradientText}>
                {RichText.asText(item.text)} <Gradient>{gradientText}</Gradient>
              </Feature>
            );
          })}
        </Features>
      </FeaturesContainer>

      {contact_us?.action_link?.url && (
        <StyledLinkButtonWithGradientHover href={contact_us.action_link.url}>
          {RichText.asText(contact_us?.action_label)}
        </StyledLinkButtonWithGradientHover>
      )}

      <VideoContainer isFullscreen={!isPreview}>
        <Video
          preload="none"
          ref={previewVideoRef as any}
          autoPlay
          loop
          controls={!isPreview}
          muted={isPreview}
          playsInline
          isFullscreen={!isPreview}
          className={'showreel__video'}
          onClick={isPreview ? handleShowreelClick : () => {}}
          key={isPreview ? '_preview' : ''}
        >
          <source
            src={`/videos/showreel${
              isPreview ? '_preview' : isSafari ? '-acc' : ''
            }.mp4${isSafari && !isPreview ? '#t=0.1' : ''}`}
            type="video/mp4"
          />
        </Video>
        {!isPreview && (
          <CloseButton onClick={() => setPreview((prev) => !prev)} />
        )}
        {isPreview && (
          <Cursor ref={cursorRef}>
            <Play
              className={'showreel__play'}
              width="144"
              height="144"
              viewBox="0 0 144 144"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M72 115C96.3005 115 116 95.3005 116 71C116 46.6995 96.3005 27 72 27C47.6995 27 28 46.6995 28 71C28 95.3005 47.6995 115 72 115ZM61 54V86L89.5867 70.2133L61 54Z"
                fill="black"
              />
            </Play>
            <Caption viewBox="0 0 400 400" className={'showreel__caption'}>
              <defs>
                <path
                  id="MyPath"
                  d="M 130, 200
                                    m -80, 0
                                    a 100,95 0 1,1 315,0"
                />
              </defs>

              <text>
                <textPath href="#MyPath">
                  {RichText.asText(cursorCaption)}
                </textPath>
              </text>
            </Caption>
          </Cursor>
        )}
      </VideoContainer>
    </Section>
  );
};
