import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { defaultContainerSize, mq } from 'styles/mixins';
import {
  Header1,
  ParagraphM,
  Subheader,
} from '../../core/Typography/Typography';
import { rgba } from 'emotion-rgba';
import { THEME } from 'common/consts/theme';
import LinkButtonWithGradientHover from 'styledComponents/LinkButtonWithGradientHover';

type VideoContainerType = {
  isFullscreen: boolean;
};

export const Section = styled.section`
  ${defaultContainerSize};
  display: flex;
  flex-wrap: wrap;
  padding-top: 2.5rem;

  ${mq.small(css`
    padding-top: 4rem;
  `)};

  ${mq.xLarge(css`
    padding-top: 7.5rem;
  `)};
`;

export const FeaturesContainer = styled.div`
  flex: 0 0 100%;
  position: relative;
  height: 2rem;
  margin-top: 1.5rem;

  ${mq.large(css`
    height: auto;
    flex: 0 0 25%;
    margin-top: 0;
  `)};

  ${mq.xLarge(css`
    flex: 0 0 15%;
  `)};

  ${mq.xxxLarge(css`
    flex: 0 0 20%;
  `)};
`;

export const Features = styled.div`
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 2rem;
  bottom: 0;
  right: 0;
`;

export const Title = styled.h1`
  ${Header1};
  color: ${({ theme }) => theme.colors.black};
  flex: 0 0 100%;
  align-items: flex-end;
  margin: 0;

  ${mq.large(css`
    flex: 0 0 75%;
  `)};

  ${mq.xLarge(css`
    flex: 0 0 85%;
  `)};

  ${mq.xxxLarge(css`
    flex: 0 0 80%;
  `)};
`;

export const Span = styled.span`
  ${Subheader};
  display: block;
`;

const featureAutoplay = keyframes`
  0% {
    top: -100%;
    opacity: 0;
  }
  3% {
    opacity: 0;
  }
  5% {
    top: 0;
    opacity: 1;
  }
  33.33% {
    top: 0;
    opacity: 1;
  }
  35.33% {
    opacity: 0;
  }
  37.33% {
    top: 100%;
    opacity: 0;
  }
  100% {
    top: 100%;
    opacity: 0;
  }
`;

export const Feature = styled.p`
  ${ParagraphM};
  color: ${({ theme }) => theme.colors.black};
  margin: 0;
  position: absolute;
  left: 0;
  top: -120%;
  animation: 12s ${featureAutoplay} infinite ease-in-out;
  user-select: none;

  &:nth-child(1) {
    animation-delay: 0s;
  }

  &:nth-child(2) {
    animation-delay: 4s;
  }

  &:nth-child(3) {
    animation-delay: 8s;
  }

  ${mq.large(css`
    left: auto;
    right: 0;
  `)};
`;

export const Gradient = styled.span`
  background: linear-gradient(
    67.33deg,
    ${THEME.colors.primary} -19.99%,
    ${THEME.colors.accent} 98.54%
  );
  background-clip: text;
  color: ${rgba(THEME.colors.core_white, 0)};
  position: relative;
`;

export const VideoContainer = styled.div<VideoContainerType>`
  margin: 2.5rem 0 0 0;
  position: relative;
  border-radius: 1rem;
  flex: 0 0 100%;
  cursor: pointer;
  z-index: 0;

  > * {
    border-radius: 1rem;
  }

  ${({ isFullscreen }) =>
    isFullscreen &&
    css`
      position: fixed;
      align-items: center;
      justify-content: center;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 100;
      border-radius: 0;
      background-color: ${THEME.colors.core_black};
      display: flex;
      cursor: default;

      > * {
        border-radius: 0;
      }
    `};

  ${mq.small(css`
    margin-top: 2rem;
  `)};

  ${mq.xLarge(css`
    margin-top: 4rem;
    cursor: none;
  `)};
`;

export const Video = styled.video<VideoContainerType>`
  border-radius: 1rem;
  width: 100%;
  height: auto;

  ${({ isFullscreen }) =>
    isFullscreen &&
    css`
      height: 100%;
      cursor: default;
    `};
`;

export const Cursor = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 300ms ease-in-out;
  opacity: 1;

  ${mq.xLarge(css`
    transform: none;
    opacity: 0;
  `)};
`;

export const Play = styled.svg`
  height: 6rem;
  width: 6rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition: height 300ms ease-in-out, width 300ms ease-in-out;

  ${mq.xLarge(css`
    height: 9rem;
    width: 9rem;
  `)};
`;

export const Caption = styled.svg`
  height: 6rem;
  width: 6rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  display: none;
  pointer-events: none;
  transition: height 300ms ease-in-out, width 300ms ease-in-out;
  font-weight: bold;
  font-size: 3.7rem;

  ${mq.xLarge(css`
    display: block;
    height: 9rem;
    width: 9rem;
  `)};
`;

export const CloseButton = styled.div`
  top: 60px;
  right: 40px;
  position: fixed;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  transition: background-color 200ms ease-in-out;
  border-radius: 0.0625rem;

  &:before {
    transform: rotate(-45deg);
  }

  &:after {
    transform: rotate(45deg);
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    height: 3px;
    width: 100%;
    top: 50%;
    left: 0;
    background-color: ${({ theme }) => theme.colors.core_white};
    border-radius: 0.0625rem;
    transition: background-color 200ms ease-in-out;
    box-shadow: 0 0 4px 0.0625rem #1700594a;
  }

  &:hover {
    -webkit-background-clip: text;
    &:before,
    &:after {
      height: 3px;
      background: linear-gradient(
        88.48deg,
        #0f4ed7 0%,
        #684b9f 59.57%,
        #d2485c 98.61%
      );
    }
  }
`;

export const StyledLinkButtonWithGradientHover = styled(
  LinkButtonWithGradientHover
)`
  transition: background-color 3000ms ease-in-out;
  margin-top: 2rem;
  padding: 0.5rem 0rem;
  max-width: 11.75rem;
  ${mq.medium(css`
    max-width: 15.1875rem;
    padding: 0.75rem 0;
  `)};
  &:hover {
    background: linear-gradient(
      67.33deg,
      ${THEME.colors.primary} -19.99%,
      ${THEME.colors.accent} 98.54%
    );
  }
`;
