import { IWindowSize, useWindowSize } from '../../../hooks/useWindowSize';
import { RefObject, useEffect, useRef, useState } from 'react';

export const useShowreel = () => {
  const windowSize: IWindowSize = useWindowSize();
  const cursorRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const previewVideoRef = useRef<HTMLVideoElement>(null);
  const [initialized, setInitialized] = useState(false);
  const [isPreview, setPreview] = useState(true);
  const [isSafari, setSafari] = useState(true);

  const animateCursor = (
    event: MouseEvent,
    ref: RefObject<HTMLDivElement>,
    windowSize: IWindowSize
  ): void => {
    // @ts-ignore
    const paths = event.composedPath();
    if (!paths) return;

    if (windowSize?.width && windowSize.width < 1200) return;

    if (!ref.current) return;

    if (
      // @ts-ignore
      paths.some((path: HTMLElement) => {
        if (!path?.classList) return false;

        const classList = Array.from(path.classList);

        return (
          classList.includes('showreel__video') ||
          classList.includes('showreel__caption') ||
          classList.includes('showreel__play')
        );
      })
    ) {
      ref.current.style.left = `${event.offsetX}px`;
      ref.current.style.top = `${event.offsetY}px`;
      ref.current.style.opacity = '1';
      ref.current.style.cursor = 'none';
    } else {
      ref.current.style.opacity = '0';
    }
  };

  useEffect(() => {
    const handleKeyDown = (evt: any) => {
      if (evt.code === 'Escape') {
        setPreview(true);
      }
    };
    document.addEventListener('keydown', handleKeyDown);

    const ua = navigator.userAgent.toLowerCase();
    const is_safari = ua.indexOf('safari/') > -1 && ua.indexOf('chrome') < 0;

    setSafari(is_safari);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (windowSize?.width && !initialized) {
      setInitialized(true);
    }
  }, [windowSize.width]);

  useEffect(() => {
    if (windowSize.width && !initialized) {
      window.addEventListener('mousemove', (event: MouseEvent) =>
        animateCursor(event, cursorRef, windowSize)
      );

      return () => {
        window.removeEventListener('mousemove', (event: MouseEvent) =>
          animateCursor(event, cursorRef, windowSize)
        );
      };
    }
  }, [windowSize.width]);

  useEffect(() => {
    if (cursorRef?.current && windowSize.width && windowSize.height) {
      cursorRef.current.style.top = '50%';
      cursorRef.current.style.left = '50%';
      cursorRef.current.style.transform = 'translate(-50%, -50%)';
      cursorRef.current.style.opacity = windowSize.width < 1200 ? '1' : '0';
    }
  }, [windowSize.width, windowSize.height]);

  function handleShowreelClick() {
    setPreview((prev) => !prev);
  }

  useEffect(() => {
    if (previewVideoRef?.current) {
      // @ts-ignore
      previewVideoRef.current.volume = 0.2;
      try {
        if (isPreview && windowSize?.width && windowSize.width <= 1200) {
          // TODO: add is currently playing property
          (previewVideoRef.current as HTMLVideoElement)?.pause();
        } else {
          (previewVideoRef.current as HTMLVideoElement)?.play();
        }
      } catch (e: unknown) {
        console.error('Could not change the video play state!');
      }
    }
  }, [windowSize.width, isPreview]);

  return {
    handleShowreelClick,
    previewVideoRef,
    isPreview,
    isSafari,
    setPreview,
    cursorRef,
  };
};
