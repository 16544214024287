import { GetStaticProps, GetStaticPropsContext } from 'next';
import { RichText } from 'prismic-reactjs';
import React from 'react';
import dynamic from 'next/dynamic';

import {
  apolloClient,
  defaultQueryResult,
  getPageQuery,
} from 'utils/apolloClient';
import { getCurrentLocale } from 'utils/getCurrentLocale';

import { HOME_QUERY } from '../graphql/queries';
import { prepareSeoFromPageData } from '../components/core/SEO/interface';
import { IndexType } from '../models/pageTypes';
import { Page } from '../models/pageTypes/page';
import { Wrapper } from '../styles/pages/styled/home.styles';
import Showreel from '../components/sections/Showreel';
import { TitleAs } from '../models/enums/titleAs';
import { CoverWithInfo } from 'components/sections/CoverWithInfo/CoverWithInfo';

const DynamicWhoWeAre = dynamic(
  () => import('../components/sections/WhoWeAre')
);
const DynamicProjects = dynamic(
  () => import('../components/sections/Projects')
);
const DynamicFaq = dynamic(() => import('../components/sections/Faq'));

const DynamicTrustedBy = dynamic(
  () => import('../components/sections/TrustedBy')
);

const Home = ({ data: { page } }: Page<IndexType>) => (
  <>
    <Wrapper>
      <Showreel
        title={page.showreel_title}
        subtitle={page.showreel_subtitle}
        cursorCaption={page.showreel_cursor_caption}
        items={page.showreel_features}
        contact_us={page.contact_us}
      />

      <DynamicTrustedBy
        companiesTitle={RichText.asText(page.howWeMakeItWork_companiesTitle)}
        companies={page.howWeMakeItWork_companies}
      />
      <DynamicWhoWeAre
        title={page.whoWeAre_title}
        highlightedDescription={page.whoWeAre_highlightedDescription}
        description={page.whoWeAre_description}
        descriptionSecondary={page.whoWeAre_descriptionSecondary}
        buttonLabel={page.whoWeAre_button}
      />
      <CoverWithInfo
        title={page.coverWithInfo_title}
        backgroundImage={page.coverWithInfo_backgroundImage}
        contactButton={page.coverWithInfo_contactButton}
      />

      <DynamicProjects
        title={RichText.asText(page.projects_title)}
        description={page.projects_description}
        items={page.projects}
        titleAs={TitleAs.h3}
      />

      <DynamicFaq
        title={RichText.asText(page.faq_title)}
        items={page.faq_items}
        titleAs={TitleAs.h4}
      />
    </Wrapper>
  </>
);

export const getStaticProps: GetStaticProps = async (
  context: GetStaticPropsContext
) => {
  const currentLanguage: string = getCurrentLocale(context);

  const {
    data: { allHome_pages, ...defaultData },
  } = await apolloClient.query(
    getPageQuery(
      {
        pageLang: currentLanguage,
      },
      'home_query',
      HOME_QUERY
    )
  );

  return {
    props: {
      data: {
        ...defaultQueryResult(defaultData),
        seo: prepareSeoFromPageData(allHome_pages.edges[0].node),
        page: allHome_pages.edges[0].node,
      },
    },
  };
};

export default Home;
