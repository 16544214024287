import { StyledLinkButton } from './linkButtonWithGradientHover.styles';
import { ILinkButtonProps } from './interfaces';

export const LinkButtonWithGradientHover = ({
  children,
  onClick,
  disabled,
  fillButton,
  fit,
  as,
  href,
  ...props
}: ILinkButtonProps) => {
  return (
    <StyledLinkButton
      disabled={disabled}
      fillButton={fillButton}
      fit={fit}
      onClick={onClick}
      as={as}
      href={href}
      {...props}
    >
      {children}
    </StyledLinkButton>
  );
};
