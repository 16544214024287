import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IButtonProps } from '../Button/interfaces';
import Link from 'next/link';

export const StyledLinkButton = styled(Link)<IButtonProps>`
  font-family: ${({ theme }) => theme.font.questrial};
  position: relative;
  width: 100%;
  max-width: 15.1875rem;
  border-radius: 3.75rem;
  padding: 0.75rem 0;
  color: ${({ theme }) => theme.colors.core_white};
  text-decoration: none;
  background-color: ${({ theme }) => theme.colors.primary};
  transition: background-color 300ms ease-in-out;
  text-align: center;
  font-size: 1.25rem;
  line-height: 2rem;
  letter-spacing: 0.0125rem;
  cursor: pointer;

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth}px;
    `};

  ${({ fillButton }) =>
    fillButton &&
    css`
      width: 100%;
      max-width: unset;
    `};

  ${({ fit }) =>
    fit &&
    css`
      width: fit-content;
      max-width: unset;
    `};

  &:after {
    content: '';
    box-shadow: 0.5rem 0.5rem 1.5rem rgba(65, 70, 176, 0.86);
    opacity: 0;
    transition: opacity 300ms ease-in-out;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4rem;
    z-index: 0;
  }

  &:hover {
    &:after {
      opacity: 1;
    }
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 3.75rem;
    background: linear-gradient(67.33deg, #0e4ed7 -19.99%, #f94743 98.54%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  &:hover {
    background-color: transparent;
  }
`;
