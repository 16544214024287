import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { mq } from 'styles/mixins';
import Image from 'next/image';
import LinkButtonWithGradientHover from 'styledComponents/LinkButtonWithGradientHover';
import { THEME } from 'common/consts/theme';
import { Header1 } from 'components/core/Typography/Typography';

export const Section = styled.section`
  margin: 0 auto;
  padding: 0rem 1rem;
  max-width: 77.125rem;
  width: calc(100% -2rem);
  max-height: 41.125rem;
  heigth: 100%;
  overflow: hidden;
  position: relative;
  ${mq.xLarge(css`
    padding: 0rem;
    width: 100%;
  `)};
`;

export const StyledImage = styled(Image)`
  width: 100%;
`;

export const StyledContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0rem;
  left: 0;
  padding: 0rem 1rem;
  margin: 0 auto;

  ${mq.large(css`
    display: flex;
    left: 0;
  `)};
`;

export const StyledContentWrapper = styled.div`
  position: absolute;
  padding: 0;
  left: 50%;
  top: 33%;
  width: 85%;
  transform: translate(-50%, -50%);

  ${mq.mSmall(css`
    top: 42%;
  `)};
  ${mq.smallIp(css`
    top: 45%;
  `)};

  ${mq.large(css`
    width: 100%;
    left: 0%;
    top: 0%;
    transform: translate(0%, 0%);
    padding-top: 18.7rem;
    padding-left: 6.313rem;
  `)};
`;

export const Content = styled.div`
  margin: 0 auto;
`;

export const Title = styled.h3`
  ${mq.mSmall(css`
    font-size: 1.75rem;
    line-height: 2.25rem;
    padding-right: 2rem;
    max-width: 20rem;
  `)};
  ${Header1};

  font-size: 1.5rem;
  line-height: 2rem;

  color: ${({ theme }) => theme.colors.core_white};
  flex: 0 0 100%;
  align-items: flex-end;
  margin: 0rem 0rem 0.5rem 0rem;
  padding-right: 2rem;
  max-width: 23rem;

  ${mq.smallIp(css`
    padding-right: 2rem;
    max-width: 20rem;
  `)};

  ${mq.small(css`
    padding-right: 0rem;
    max-width: 30rem;
  `)};

  ${mq.medium(css`
    padding-right: 0rem;
    flex: 0 0 70%;
    max-width: 32rem;
  `)};
  ${mq.large(css`
    flex: 0 0 70%;
    max-width: 32rem;
  `)};

  ${mq.xLarge(css`
    flex: 0 0 65%;
    margin: 0rem 0rem 1.8rem 0rem;
    max-width: 45rem;
  `)};
`;

export const StyledLinkButtonWithGradientHover = styled(
  LinkButtonWithGradientHover
)`
  transition: background-color 3000ms ease-in-out;
  position: absolute;
  padding: 0.5rem 0rem;
  max-width: 11.75rem;
  ${mq.medium(css`
    max-width: 15.1875rem;
    padding: 0.75rem 0;
  `)};
  &:hover {
    background: linear-gradient(
      67.33deg,
      ${THEME.colors.primary} -19.99%,
      ${THEME.colors.accent} 98.54%
    );
  }
`;
